import React from "react"
import { MenuTheme } from "antd/lib/menu/MenuContext"
import Menu, { MenuMode } from "antd/lib/menu"
import SubMenu from "antd/lib/menu/SubMenu"
import styled from "styled-components"
import { useRouter } from "next/router"

const Wrapper = styled.div`
  & .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0px;
  }
`

interface MenuGeneratorProps {
  theme?: MenuTheme
  mode?: MenuMode
  className?: string
  defaultSelectedKeys?: string[]
  defaultOpenKeys?: string[]
  items?: MenuItem[]
  selectedKeys?: string[]
}

interface MenuItem {
  key: string
  onClick?: () => void
  icon?: JSX.Element
  name: string
  children?: MenuItem[]
  disabled?: boolean
}

const renderMenuItem = (items: MenuItem[]): JSX.Element[] => {
  const router = useRouter()
  const menuItems: JSX.Element[] = []
  for (const item of items) {
    let children: JSX.Element[] = []
    if (item.children) {
      children = renderMenuItem(item.children)
    }
    if (children.length > 0) {
      menuItems.push(
        <SubMenu
          key={item.key}
          title={
            <span>
              {item.icon}
              <span>{item.name}</span>
            </span>
          }
          disabled={item.disabled}
        >
          {children}
        </SubMenu>
      )
    } else {
      menuItems.push(
        <Menu.Item
          key={item.key}
          onClick={item.onClick}
          disabled={item.disabled}
          className={item.key === router.pathname.split("/")[1] ? "active" : ""}
        >
          {item.icon}
          <span>{item.name}</span>
          {children}
        </Menu.Item>
      )
    }
  }
  return menuItems
}

const MenuGenerator: React.FC<MenuGeneratorProps> = ({
  theme,
  className,
  defaultSelectedKeys,
  defaultOpenKeys,
  items,
  selectedKeys,
}) => {
  return (
    <Wrapper>
      <Menu
        theme={theme}
        className={className}
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenKeys}
        selectedKeys={selectedKeys}
      >
        {items && renderMenuItem(items)}
      </Menu>
    </Wrapper>
  )
}

export { MenuGenerator }
