import { API } from "@project/shared"

export const updateCompanySettings = async (values) => {
  const id = values.id
  return API.put(`/companies/${id}`, values)
}

export const updateCompanyMemberSettings = async (values) => {
  const id = values.id
  return API.put(`/company-members/${id}`, values)
}

export const getCompanySettings = async (uid) => {
  return API.get(`/companies/${uid}`, { params: { is_fb_uid: true } })
}

export const getCompanyMemberSettings = async (uid) => {
  return API.get(`/company-members/${uid}`, { params: { is_fb_uid: true } })
}
