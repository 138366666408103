import { API } from "@project/shared"
interface IStatusFilter {
  date_from?: string
  date_to?: string
  referrer_id?: string
  agent_id?: string
  agent_company_id?: string
}

const fetchStatusCount = async ({ queryKey }) => {
  const params = {} as IStatusFilter
  params.date_from = queryKey[1].from
  params.date_to = queryKey[1].to
  params.agent_id = queryKey[1].staff
  params.agent_company_id = queryKey[1].company
  return API.get(
    queryKey[1].graph === "last-status-graph"
      ? `/last-status/count`
      : `/status/count`,
    {
      params,
    }
  )
}
const fetchJobOfferCount = async ({ queryKey }) => {
  const params = {} as IStatusFilter
  params.date_from = queryKey[1].from
  params.date_to = queryKey[1].to
  params.referrer_id = queryKey[1].staff
  return API.get(`/job-offer/count`, {
    params,
  })
}

export { fetchJobOfferCount, fetchStatusCount }
