import { useState, useEffect, useContext } from "react"
import * as Sentry from "@sentry/node"
import { AppProps } from "next/app"
import Head from "next/head"
import { message } from "antd"
import { analytics, firebase } from "@project/shared"
import { QueryClient, QueryClientProvider } from "react-query"
import { useRouter } from "next/router"
import { useTranslation } from "react-i18next"
import { AuthContext, AuthProvider } from "../utils/AuthContext"
import "../utils/css-imports"
import "../styles.css"
import { CloseCircleFilled } from "@ant-design/icons"
import { getCompanyMemberSettings } from "../services"

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV !== "development",
    environment: `owner-${process.env.NODE_ENV}`,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  })
}

const queryClient = new QueryClient()

const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter()
  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState(null as firebase.User | null)
  const [currentUser, setCurrentUser] = useState(null)
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { setUser: dispatchUser } = useContext(AuthContext)
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      const logEvent = (url: string) => {
        analytics().setCurrentScreen(url)
        analytics().logEvent("screen_view", {
          screen_name: url,
          app_name: "Skeleton-Owner",
        })
      }

      routers.events.on("routeChangeComplete", (url) => {
        window.scrollTo(0, 0)
        logEvent(url)
      })

      logEvent(window.location.pathname)
      return () => {
        routers.events.off("routeChangeComplete", logEvent)
      }
    }
  }, [])

  useEffect(() => {
    const clearLocalJobFormData = () => {
      if (
        localStorage.getItem("jobFormState") === "1" &&
        (routers.asPath !== "/jobs/add" ||
          routers.asPath.indexOf("/jobs/edit") === -1)
      ) {
        localStorage.removeItem("jobFormState")
        localStorage.removeItem("jobForm")
      }
    }
    routers.events.on("routeChangeComplete", clearLocalJobFormData)
    return () => {
      routers.events.off("routeChangeComplete", clearLocalJobFormData)
    }
  }, [routers.events])

  const injectCurrentUser = async (
    token?: string,
    user?: firebase.User | null
  ) => {
    let currentUser
    try {
      if (token) {
        await firebase.auth().signInWithCustomToken(token)
        currentUser = firebase.auth().currentUser
      } else {
        currentUser = user
      }
      if (!currentUser) return
      const idToken = await currentUser.getIdTokenResult()
      const role = idToken.claims["role"]
      setUser({ role, ...currentUser })
    } catch (error) {
      firebase.auth().signOut()
      message.error({
        content: t("Unauthorized user"),
        key: "39",
        icon: <CloseCircleFilled onClick={() => message.destroy("39")} />,
      })
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!routers.isReady) return
    const custom_token = routers.query?.custom_token
    if (custom_token) {
      const token = String(custom_token)
      firebase.auth().signOut()
      injectCurrentUser(token)
    } else {
      firebase.auth().onAuthStateChanged((user) => {
        injectCurrentUser("", user)
      })
    }
  }, [routers.isReady])

  useEffect(() => {
    if (routers.pathname === "/login") {
      firebase.auth().onAuthStateChanged((user) => {
        user && injectCurrentUser("", user)
      })
    }
  }, [routers.pathname])

  const refreshCurrentUser = () => {
    if (user?.uid != firebase.auth().currentUser?.uid) {
      window.location.reload()
    }
  }

  useEffect(() => {
    window.addEventListener("focus", refreshCurrentUser)
    return () => {
      window.removeEventListener("focus", refreshCurrentUser)
    }
  })

  // this useEffect is used to Fetch data of current login user from the database
  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const data = await getCompanyMemberSettings(user?.uid)
          setCurrentUser(data.data)
        } catch (error) {
          setCurrentUser(null)
          message.error({
            content: t("Unauthorized user"),
            key: "39",
            icon: <CloseCircleFilled onClick={() => message.destroy("39")} />,
          })
        }
      }
      fetchData()
    }
  }, [user])
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <title>Sales-agent</title>
          <link rel="icon" href="/assets/favicon.ico" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap"
            rel="stylesheet"
          />
        </Head>
        <AuthProvider
          loading={loading}
          user={user}
          setUser={setUser}
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
        >
          <Component {...pageProps} />
        </AuthProvider>
      </QueryClientProvider>
    </>
  )
}
export default MyApp
