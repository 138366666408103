import { Dispatch, createContext } from "react"
import { firebase } from "@project/shared"

type ContextProps = {
  loading: boolean
  user: firebase.User | null
  authenticated: boolean
  setUser: any
  currentUser: any
  setCurrentUser: Dispatch<unknown>
}

export const AuthContext = createContext<Partial<ContextProps>>({})

type AuthProviderProps = {
  loading: boolean
  user: any | null
  setUser: any
  children: React.ReactNode
  currentUser: unknown
  setCurrentUser: Dispatch<unknown>
}

export const AuthProvider = (props: AuthProviderProps) => {
  const { loading, user, setUser, children, currentUser, setCurrentUser } =
    props
  return (
    <AuthContext.Provider
      value={{
        loading,
        user,
        authenticated: user !== null,
        setUser,
        currentUser,
        setCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const getUserMetadata = async (user: firebase.User | null) => {
  const metadata = await user?.getIdTokenResult()
  return {
    uid: metadata?.claims["user_id"] || "",
    role: metadata?.claims["role"] || "",
  }
}
