import { API } from "@project/shared"
export interface IReturnSelectionList {
  count?: number
  data?: any
}

export interface ISearchSelectionQuery {
  page: string
  size: string
  keyword: string
  applicant_status?: string
  applicant_name?: string
  person_in_charge?: string
}

export const fetchSelection = async ({
  queryKey,
}): Promise<IReturnSelectionList> => {
  const params = {} as ISearchSelectionQuery
  params.page = queryKey[1].page
  params.size = queryKey[1].size
  params.keyword = queryKey[1].keyword
  params.applicant_status = queryKey[1].applicantStatus
  params.applicant_name = queryKey[1].applicantName
  params.person_in_charge = queryKey[1].personInCharge
  return API.get(`/job-applicants`, { params })
}

export const getSelection = async (id) => {
  return API.get(`/job-applicants/${id}`)
}

export const addSelection = async (values): Promise<any> => {
  return API.post(`/job-applicants`, values)
}

export const updateSelection = async ({ id, values }) => {
  return API.put(`/job-applicants/${id}`, { status: values })
}

export const deleteSelection = async (id) => {
  return API.delete(`/job-applicants/${id}`)
}

export const applicationStatusCountForCompany = async ({ queryKey }) => {
  const params = {} as ISearchSelectionQuery
  params.keyword = queryKey[1].keyword
  params.applicant_status = queryKey[1].applicantStatus
  params.applicant_name = queryKey[1].applicantName
  params.person_in_charge = queryKey[1].personInCharge
  return API.get(`/job-applicant-status-list-company`)
}

export const getMemberNamesWithId = async (id) => {
  return API.get(`/job-applicants/names/${id}`)
}

export const updateSelectionReason = async ({ id, status, comments }) => {
  return API.put(`/job-applicants/${id}`, {
    status: status,
    comments: comments,
  })
}
